import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import Pagination from "../components/Pagination"
import EventListItem from "../components/ListItems/EventListItem"
import PageHeader from "../components/PageHeader"

const EventList = ({ pageContext, data, location }) => {
  const { currentPage, numPages } = pageContext

  return (
    <Layout>
      <Metas title="Agenda" />
      <PageHeader title="Agenda" subtitle="Vivre à Coucy" />
      <section className="section-bottom page-content">
        <div className="container mx-auto px-4">
          <div className="columns grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8 mt-12">
            {data.events.nodes.map((node, index) => (
              <div key={index} className="column event">
                <EventListItem content={node} />
              </div>
            ))}
          </div>

          <Pagination
            path={location.pathname}
            currentPage={currentPage}
            numPages={numPages}
          />
        </div>
      </section>
    </Layout>
  )
}

export default EventList

export const query = graphql`
  query eventListQuery($skip: Int!, $limit: Int!) {
    events: allNodeEvenement(
      filter: { status: { eq: true }, is_forthcoming: { eq: true } }
      sort: { fields: field_date___value, order: ASC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title
        dates: field_date {
          start: value(formatString: "YYYY-MM-DDTHH:mm:ssZ", locale: "fr")
          end: end_value(formatString: "YYYY-MM-DDTHH:mm:ssZ", locale: "fr")
        }
        place: field_emplacement
        relationships {
          image: field_image {
            localFile {
              childImageSharp {
                fluid(
                  maxWidth: 332
                  maxHeight: 208
                  cropFocus: CENTER
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        path {
          alias
        }
      }
    }
  }
`
